import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "../globalStyles.module.css";
import { useAppStore } from "../store/appStore";
import { Routes } from "../utils/routes";
import { carouselData } from "../data";

function Home() {
  const [items, setItems] = useState([]);

  const getImgUrl = img => `/carousel/${img}.jpg`

  const goToRoute = useAppStore(({ setRoute }) => setRoute);

  const goToGallery = () => {
    goToRoute(Routes.works);
  };

  useEffect(() => {
    const imgs = carouselData.map((_, index) => index)
    setItems(imgs)
  }, []);

  return (
    <div className={styles["carousel-container"]}>
      <Carousel
        fade
        controls={false}
        indicators={false}
        interval={2000}
      >
        {items.map(img => (
          <Carousel.Item key={img}>
            <Carousel.Caption className={styles["carousel-element"]}>
              <img
                className={styles["carousel-element-img"]}
                src={getImgUrl(img)}
                onClick={goToGallery}
              />
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Home;
