import styles from "../../globalStyles.module.css"

function ThumbnailImg({ img, selected }) {
  const { id, vertical, src } = img
  const thumbnailClass = vertical ? 'thumbnail-img-vertical' : 'thumbnail-img'

  return (
    <img
      className={styles[thumbnailClass]}
      style={selected ? {outline: '4px white solid'} : {}}
      src={src}
      alt={id}
    />
  )
}

export default ThumbnailImg