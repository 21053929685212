import styles from "../../globalStyles.module.css";

function ImgDescription({ description, title }) {
  return (
    <div className={styles["work-description"]}>
      <div style={{ fontWeight: 700, fontSize: '1.3em' }}>
        {title}
      </div>
      <div>
        {description}
      </div>
    </div>
  )
}

export default ImgDescription