import styles from "../globalStyles.module.css";
import Item from "../components/Item";
import { worksData } from "../data";

function Works() {
  return (
    <div className={styles["items-view"]}>
      <div className={styles["items-container"]}>
        {worksData.map(item => <Item {...item} key={item.id} />)}
      </div>
    </div>
  );
}

export default Works;
