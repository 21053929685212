// leaving titles for now
export const carouselData = [
  'danse macabre',
  'sumo tv',
  'life = fight'
]

export const worksData = [
  {
    id: 'danse-macabre',
    title: 'danse macabre',
    count: 6,
    vertical: [0],
    description: 'tapestry – wool, silk – 130 x 100cm – 2023'
  },
  {
    id: 'life=fight',
    title: 'life = fight',
    count: 10,
    vertical: [],
    description: 'tapestry – wool, cotton with Ingeo fiber – 92 x 112cm – 2022'
  },
  {
    id: 'never-ending-bayeux',
    title: 'never ending bayeux',
    count: 8,
    vertical: [],
    description: 'tapestry – wool – 91 x 114cm – 2022'
  },
  {
    id: 'danger-all-over',
    title: 'danger all over',
    count: 4,
    vertical: [],
    description: 'tapestry – wool – 100 x 100cm – 2022'
  },
  {
    id: 'space-is-the-only-noise',
    title: 'space is the only noise',
    count: 4,
    vertical: [0],
    description: 'tapestry – wool – 69 x 69cm – 2021'
  },
  {
    id: 'deep-blue',
    title: 'deep blue',
    count: 3,
    vertical: [],
    description: 'tapestry – wool, silk – 19 x 19cm – 2022'
  },
  {
    id: 'great-escape',
    title: 'great escape',
    count: 4,
    vertical: [],
    description: 'tapestry – wool, silk – 25 x 21cm – 2022'
  },
  {
    id: 'sumo-tv',
    title: 'sumo tv',
    count: 4,
    vertical: [0],
    description: 'embroidery, old TV set, - silk, beads – 20 x 22 x 23cm - 2023'
  },
  {
    id: 'invasion',
    title: 'invasion',
    count: 4,
    vertical: [0],
    description: 'embroidery, old window frame I – embroidery, old window frame II'
  },
  {
    id: 'darger-updated',
    title: 'darger updated',
    count: 4,
    vertical: [],
    description: 'embroidery, old widow frame – cotton thread, linen – 37 x 46cm – 2022'
  },
  {
    id: 'moscitos',
    title: 'moscitos',
    count: 2,
    vertical: [],
    description: 'embroidery, old window frame – cotton thread, organza – 52x55cm – 2018'
  },
  {
    id: 'sifted-flies',
    title: 'sifted-flies',
    count: 2,
    vertical: [0, 1],
    description: 'embroidery,metal sieve /diameter 30cm/ - cotton thread, velvet pieces – 2018'
  },
  {
    id: 'little-ones',
    title: 'little ones',
    count: 1,
    vertical: [0],
    description: 'embroidery, plastic sieve / diameter 5cm/ - cotton thread, velvet pieces – 2018'
  }
]
