import styles from "../../globalStyles.module.css";
import { getFileName } from "../../utils/helpers";

function EnlargedImg({ img, work, setNext, setPrevious, closeModal }) {
  const { id, count, vertical } = work;

  const moreThanOne = count > 1;
  const enlargedClass = vertical.includes(img?.id)
    ? "enlarged-img-vertical"
    : "enlarged-img";

  return (
    <div className={styles["enlarged-img-box"]}>
      <button className={styles["work-close-btn"]} onClick={closeModal}>
        x
      </button>
      {moreThanOne && (
        <button className={styles["enlarged-img-btn"]} onClick={setPrevious}>
          {"<"}
        </button>
      )}

      <img
        className={styles[enlargedClass]}
        src={`works/${id}/${getFileName(img.id)}.jpg`}
        alt={id}
        onClick={setNext}
      />

      {moreThanOne && (
        <button className={styles["enlarged-img-btn"]} onClick={setNext}>
          {">"}
        </button>
      )}
    </div>
  );
}

export default EnlargedImg;
