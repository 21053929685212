import styles from "../globalStyles.module.css";
import { Routes } from "../utils/routes"
import RouterMenuBtn from "./RouterMenuBtn"

function RouteMenu({ selectedRoute }) {

  return (
    <div className={styles["route-menu"]}>
      {Object.keys(Routes).map(route => (
        <RouterMenuBtn 
          route={route} 
          isSelected={selectedRoute === route}
          key={route}
        />
      ))}
    </div>
  )
}

export default RouteMenu