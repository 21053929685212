import Home from "../pages/Home";
import Exhibitions from "../pages/Exhibitions";
import Bio from "../pages/Bio";
import Contact from "../pages/Contact";
import Works from "../pages/Works";
import styles from "../globalStyles.module.css";
import RouteMenu from "./RouteMenu";
import { useAppStore } from "../store/appStore";
import { Routes } from "../utils/routes";
import { useEffect, useRef } from "react";

function MainSection() {
  const selectedRoute = useAppStore(({ route }) => route)
  const main = useRef(null) // for mobile
  const page = useRef(null) // for desktop

  const mainComponent = {
    [Routes.home]: <Home />,
    [Routes.bio]: <Bio />,
    [Routes.works]: <Works />,
    [Routes.exhibitions]: <Exhibitions />,
    [Routes.contact]: <Contact />
  }[selectedRoute]

  useEffect(() => {
    [main, page].forEach(el => el.current.scrollTo(0, 0))
  }, [selectedRoute])

  return (
    <div
      ref={main}
      className={styles["main-section"]}
    >
      <div className={styles["main-section-left-menu"]}>
        <RouteMenu selectedRoute={selectedRoute} />
      </div>

      <div 
        ref={page}
        className={styles["main-section-right"]}
      >
        {mainComponent}
      </div>
    </div>
  );
}
export default MainSection;
