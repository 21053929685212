import { useEffect, useState } from "react";
import styles from "../../globalStyles.module.css";
import { useAppStore } from "../../store/appStore";
import EnlargedImg from "./EnlargedImg";
import ThumbnailGallery from "./ThumbnailGallery";
import ImgDescription from "./ImgDescription";
import { getFileName, getImgDetails } from "../../utils/helpers";

function WorkGallery(work) {
  const { id, title, count, vertical, description } = work

  const [img, setImg] = useState(getImgDetails({ workId: id, id: 0 }))

  const setWork = useAppStore(({ setSelectedWork }) => setSelectedWork)

  const setPrevious = () => {
    const newId = img.id ? img.id - 1 : count - 1
    setImg(getImgDetails({ workId: id, id: newId }))
  }

  const setNext = () => {
    const newId = img.id === count - 1 ? 0 : img.id + 1
    setImg(getImgDetails({ workId: id, id: newId }))
  }

  const closeModal = () => setWork(null)
  
  const imgList = Array.from({ length: count }, (_, i) => ({
    id: i,
    src: `works/${id}/${getFileName(i)}.jpg`,
    vertical: vertical.includes(i)
  }))


  useEffect(() => {
    const handleKeyDown = e => {
      e.preventDefault()

      const { key } = e
  
      const keyEvents = {
        ArrowLeft: setPrevious,
        ArrowRight: setNext,
        Escape: closeModal
      }
  
      const fn = keyEvents[key]
  
      if (fn) fn()
    }

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [img]);

  return (img &&
    <>
      <div
        className={styles["modal"]}
        onClick={closeModal}
      >
      </div>
      <div className={styles["modal-content"]}>
        <div className={styles["work-container"]}>
          <EnlargedImg 
            img={img} 
            work={work}
            setNext={setNext}
            setPrevious={setPrevious}
            closeModal={closeModal}
          />
          <ImgDescription
            description={description}
            title={title}  
          />
          <ThumbnailGallery
            imgList={imgList}
            selectedId={img.id}
            setImg={setImg}
          />
        </div>
      </div>
    </>

  )
}

export default WorkGallery