import ThumbnailImg from "./ThumbnailImg";
import styles from "../../globalStyles.module.css";
import { useEffect, useRef } from "react";

function ThumbnailGallery({ imgList, setImg, selectedId }) {
  const gallery = useRef(null)

  useEffect(() => {
    const smallerIds = imgList.filter(({ id }) => id < selectedId)
    const horizontalWidth = 112.5 + 18
    const verticalWidth = 72 + 18

    const horizontalSmallerCount = smallerIds?.filter(({ vertical }) => !vertical)?.length || 0
    const verticalSmallerCount = smallerIds?.filter(({ vertical }) => vertical)?.length || 0

    const totalWidth = horizontalSmallerCount * horizontalWidth + verticalSmallerCount * verticalWidth

    gallery.current.scrollLeft = totalWidth - 30
  }, [selectedId])

  return (
    <div className={styles["thumbnail-gallery-container"]}> 
      <div 
        className={styles["thumbnail-gallery"]}
        ref={gallery}
      >
        {imgList.map(img => (
          <div

            onClick={() => setImg(img)}
            key={img.id}  
          >
            <ThumbnailImg
              img={img}
              selected={selectedId === img.id}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ThumbnailGallery