import { worksData } from "../data"

export const getImgUrl = imgId => `https://drive.google.com/uc?export=view&id=${imgId}`

export const getFileName = id => {
  const num = id + 1
  return num < 10 ? `0${num}` : num
}

export const getImgDetails = ({ workId, id }) => {
  const { vertical, description } = worksData.find(item => item.id === workId)
  return {
    id,
    src: `works/${workId}/${getFileName(id)}.jpg`,
    vertical: vertical.includes(id),
    description
  }
}